.rewards {
    .centerScreen {
        background-color: #ffbc38;
        .info {
            table {
                th {
                    background-color: #ffbc38;
                }
                .tablecol, .tablecell {
                    width: 28%!important;
                }
            }
        }
    }
}