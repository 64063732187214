header {
    background-color: #000;
    padding-top: 24px;
    border-bottom: 7px solid #72c4db;
    
    .logo {
        img {
            width: 153px;
            position: absolute;
            top: 14px;
            z-index: 2000;
        }
    }

    .menu{
        display: flex;
        justify-content: space-between;
        align-items: start;

        & > a {
            margin-top: 7px;
        }

        a, p {
            color: #72c4db;
            margin: 0 14px;
            font-size: 17px;
        }

        p.userName {
            margin-top: 8px;
        }

        a {
            transition: 0.5s all ease;
        }

        a.userName {
            margin-bottom: 24px;
        }

        a:hover {
            color: #fff;
            transition: 0.5s all ease;
        }

        .signup-btn-wrapper p {
            margin-top: 12px;
            text-align: center;
            font-weight: bold;
            font-size: 11px;
            margin-bottom: 6px;
        }

        a.signUpBtn, a.signOutBtn, .signUpBtnMobile  {
            padding: 7px 16px;
            background-color: #72c4db;
            color: #000;
            font-weight: 750;
            border-radius: 30px;
        }
        .signUpBtnMobile {
            position: absolute;
        }
        a.signUpBtn:hover,  a.signOutBtn:hover {
            background-color: #fff;
        }
    }
    
    .signUpBtnMobile {
        display: none;
        height: 7px;
        background-color: #72c4db;
        margin-top: 24px;

        .signup-btn-wrapper {
            width: 214px;
            margin-left: auto;
            text-align: center;
            p {
                font-size: 13px;
                font-weight: bold;
            }
        }
        &.active-user {
            margin-top: 0;
        }
    }

    .aboutUsModal {
        background-color: #72c4db;
        z-index: 1000;
        position: absolute;
        padding: 32px 28px;
        top: 67px;
        .closeAboutUs {
            justify-content: flex-end;
            button {
                padding: 0;
                background: none;
                border: none;
                img {
                    width: 36px;
                }
            }
        }
        p {
            margin-top: 100px;
            
        }
    }
}