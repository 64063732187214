.successReg {
    padding: 70px 0;
    max-width: 42%;
    .title {
        font-weight: 500;
        font-size: 22px;
    }
    p{
        margin-top: 25px;
    }
    .links-store {
        display: flex;
        align-items: center;
        a img{
            max-width: 280px;
        }
        .google-play img {
            max-width: 150px;
        }
    }
}