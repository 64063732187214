@media screen and (max-width: 991px) {

    .parentZoneMain main .screen {
        background-color: #dcdcdc !important;
        padding-right: 0 !important;
    }

    .parentZoneMain footer {
        margin-top: 0 !important;
    }
}

@media screen and (max-width: 991px) {

    .parentZoneMain main {
        background: linear-gradient(180deg, #fff 0%, #fff 74%, #c3c3c3 100%) !important;

        .screen {
            background: none !important;

            .signup {
                padding-top: 40px;

                .text p {
                    font-size: 19px;
                }

                .formInputDiv {
                    width: 32%;

                    input {
                        width: 100%;
                    }
                }

                form {

                    .formTitle,
                    .signup-closed-message .title {
                        font-size: 18px;
                    }

                    .formInputDiv {
                        label {
                            font-size: 16px;
                        }

                        input {
                            font-size: 18px;
                        }

                        img {
                            width: 20px;
                        }
                    }

                    .formConfirm {
                        .checkbox p {
                            font-size: 16px;
                        }
                    }

                    .inactiveSubmit {
                        width: 286px;
                    }

                    input[type=submit] {
                        font-size: 25px;
                    }

                }
            }
        }
    }

    .signup form .selectList img {
        background-color: #ffffff;
    }

    .signup .popup1 .popupClose {
        left: 267px;
        top: 10px;
    }
}

@media screen and (max-width: 809px) {

    .error {
        max-width: none !important;
    }

    .parentZoneMain main .screen {
        padding-bottom: 40px;
    }

    .parentZoneMain main .screen .signup {
        padding-top: 40px;
    }

    .signup .text {
        margin-top: 25px;
    }

    .signup .text img {
        width: 10px;
    }

    .parentZoneMain main .screen .signup .text p {
        font-size: 16px;
    }

    .signup form {
        margin-top: 39px;
    }

    .parentZoneMain main .screen .signup form .formTitle,
    .signup-closed-message .title {
        font-size: 17px;
    }

    .signup form .formTitle,
    .signup-closed-message .title {
        margin-top: 30px;
    }

    .parentZoneMain main .screen .signup form .formInputDiv img {
        width: 18px;
    }



    .signup form .selectList .selectInput,
    .signup form .selectList .selectOption {
        font-size: 16px;
    }

    .signup form .selectList img {
        width: 37px;
    }

    .signup form .formConfirm {
        margin-top: 60px;
        display: block;
    }

    .signup form .activeSubmit {
        width: 276px;
        margin: 0 auto;
        display: block;
        margin-top: 45px;

    }

    .signup form .inactiveSubmit {
        padding: 8px;
        box-shadow: none;
        width: 233px !important;
        border-radius: 35px;
        margin: 0 auto;
        margin-top: 50px;
    }

    .parentZoneMain main .screen .signup form input[type=submit] {
        font-size: 22px;
        width: 215px;
    }

    .signup .popup1 .popupClose {
        left: 267px;
        top: 11px;
    }
}

@media screen and (max-width: 768px) {
    .parentZoneMain .pre-banner {
        top: 12%;
        font-size: 14px;
        width: 240px;
    }

    .parentZoneMain main .screen .signup {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .signup form .activeSubmit {
        input {
            width: 187px;
            padding: 10px 61px;
        }
    }
}

@media screen and (max-width: 576px) {

    .d-m {
        display: inline-block !important;
        width: 36px !important;
        margin-bottom: -11px !important;
    }

    .d-m2 {
        margin-bottom: 0 !important;
        margin-left: 8px;
        display: inline-block !important;
    }

    .signup form>div {
        display: block;
    }

    .signup form .formTitle,
    .signup-closed-message .title {
        margin-bottom: 14px;
    }

    .parentZoneMain main .screen .signup .formInputDiv {
        width: 98%;
        margin-top: 15px;
        margin-left: 0 !important;
    }

    .parentZoneMain main .screen .signup form .formInputDiv div:not(.popup) img,
    .signup form .qIcon2 {
        display: none;
    }


    .signup .popup1 {
        right: 0px;
        left: 50px;

        .popupClose {
            display: block !important;
            left: 268px;
            top: 12px;
        }

        .popupText {
            top: 24px;
        }
    }


    .password {
        margin-top: 20px;
    }

    .parentZoneMain main .screen .signup form .formInputDiv input[type="password"],
    .parentZoneMain main .screen .signup form .formInputDiv input[name="yearBirth"] {
        width: 300px;
    }

    .signup form .selectList {
        display: flex !important;
        justify-content: flex-start;
    }

    .signup form .selectList .selectInput {
        padding-top: 9px !important;
        padding-bottom: 9px !important;
    }

    .signup form .selectList .selectInput,
    .signup form .selectList .selectOption {
        width: 300px;
    }

    .signup form .selectList .selectOption {
        margin-top: 44px;
    }

    .signup form .selectList .selectInput p {
        font-size: 13px;
    }

    .signup form .formConfirm {
        margin-top: 70px;
    }
}

@media screen and (max-width: 414px) {
    .parentZoneMain .pre-banner {
        top: 12%;
        font-size: 14px;
        width: 210px;
    }

    .parentZoneMain main .screen {
        padding-top: 70px;
    }

    .parentZoneMain main .screen .signup {
        padding-top: 0;
    }

    .parentZoneMain main .screen .signup form .formInputDiv {
        margin-top: 8px;
    }

    .parentZoneMain main .screen .signup form .formInputDiv br {
        display: none;
    }

    .parentZoneMain main .screen .signup form .formInputDiv label {
        display: none;
    }

    .parentZoneMain main .screen .signup form .formInputDiv input[type=password],
    .parentZoneMain main .screen .signup form .formInputDiv input[name=yearBirth] {
        width: 83%;
    }

    .signup .popup1 {
        left: 0;
    }

    .signup .popup2 {
        right: 226px;
    }

    .signup form .selectList .selectInput {
        height: 53px;
        width: 229px;
        display: flex;
        align-items: center;
    }

    .signup form .selectList img:not(.d-m2) {
        width: 53px;
    }

    .signup form .selectList .selectOption {
        width: 229px;
        margin-top: 60px;
    }
}

@media screen and (max-width: 375px) {
    .parentZoneMain .pre-banner {
        top: 16%;
        font-size: 14px;
        width: 210px;
    }
}

@supports (-webkit-touch-callout: none) {}