.uploads{
    .screen {
        padding: 0 10px;
        padding-top: 10px
    }
    .centerScreen {
        background-color: #6ff6b0;
        .info{
            min-height: 100vh;
            .uploadsTitle {
                font-size: 18px;
            }
            table {
                margin-top: 20px;
                width: 100%;
                text-align: left;
                th, td {
                    padding: 10px 15px;
                    word-wrap: break-word;
                }
                th {
                    font-size: 18px;
                    background-color: #6ff6b0;
                    width: 40%;
                }
                .tableFileCol, .tableFileCell {
                    width: 20%;
                    border-left: 1px solid #fff;
                }
                .tableFileCell:hover {
                    cursor: pointer;
                }
                .tablecol, .tablecell {
                    width: 40%;
                }
                tbody {
                    tr:nth-child(odd){
                        background-color: #dcdcdc;
                    }
                    tr:nth-child(even){
                        background-color: #f3f3f3;
                    }
                }
            }
            div.table-text {
                margin-top: 125px;
            }
            .tableMobile {
                display: none;
            }
            .table-text, .table-files {
                max-height: 290px;
                overflow-y: scroll;
            }
        }
    }

}

