@media screen and (max-width: 768px){

    .signin form{
        padding-bottom: 80px;
    }

    .signin form .inactiveSubmit {
        width: 208px;
        padding: 10px 58px;
    }

    .signin form .activeSubmit {
        width: 208px;
    }

    
}

@media screen and (max-width: 758px){

    .signin .title {
        text-align: center;
    }

    .signin form a {
        margin-right: 0;
        margin-left: 160px;
    }
}

@media screen and (max-width: 414px){

    .signin {
        background: linear-gradient(180deg, #fbfbfb 0%, #c3c3c3 100%);
    }

    .signin .title {
        font-size: 18px;
    }

    .signin form input {
        width: 270px;
        margin-top: 10px;
        padding: 8px 24px;
    }

    .signin form a {
        margin-left: 108px;
    }
}

@supports (-webkit-touch-callout: none) { 

    .signin form{
        padding-bottom: 80px;
    }
}