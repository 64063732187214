.parentMenuResponsive {
    background-color: #fff;
    padding-top: 20px;
    .container {
        max-width: 430px;
    }
    a {

        img {
            margin-bottom: -4px;
            width: 60px;
        }
    }
}

@media screen and (max-width: 576px) {

    .parentMenuResponsive a img {
        margin-bottom: -4px;
        width: 51px;
    }
}