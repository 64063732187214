:root {
    --container-padding: 0 20px;
    @media screen and (max-width: 768px){
        .container{
            padding: var(--container-padding)!important;
        }
    }
}


@media screen and (min-width: 1500px){
    main .block1_parallax img {
        width: 104%!important;
    }
}

@media screen and (max-width: 1544px){
    main .block2 .textBody {
        max-width: 97%;
    }

}

@media screen and (max-width: 1514px){
    main .block2 .signup {
        width: 1005px;
        
    }
    main .block1_parallax img {
        left: 44% !important;
        width: auto!important;
    }
    main .block3 {
        
        .text {
            max-width: 90%;
        }
    }

    
    main .block8 .img{
        width: 550px;
        .screen {
            width: 504px;
            height: 282px;
            top: 23px;
            left: 23px;
        }
    }
}



@media screen and (max-width: 1460px){

    main .block4 .right figure figcaption {
        margin-top: -165px;
    }
    
}

@media screen and (max-width: 1400px){

    main .block3 .container > div:not(.slider-wrpr) {
        margin-left: 0;
    }

    main .block9 .carouselRow .carouselContent {
        margin-top: -35px;
    }
}

@media screen and (max-width: 1370px){
    main .block1_parallax img {
        max-height: 819px !important;
        top: 1%;
        left: 26% !important;
    }
    main .block4 .leftRight {
        justify-content: flex-start;
    }

    main .block4 .right figure figcaption {
        margin-top: -154px;
    }

    main .block7 .list {
        max-width: none;
    }

    main .block9 .btnsRow {
        top: 13px;
        left: -3%;
    }
}

@media screen and (max-width: 1300px){

    h2 {
        font-size: 80px;
    }

    h3 {
        font-size: 55px;
    }

    main .block1_parallax img {
        left: 23% !important;
    }

    main .block3 .container > div:not(.slider-wrpr) {
        margin-left: 0%;
    }

    main .block5 .leftRight .slider .carouselImg {
        width: 694px;
        height: 528px;
    }

    main .block4 .right figure figcaption {
        margin-top: -123px;
        font-size: 23px;
    }

    main .block4 .right figure figcaption {
        margin-left: 37%;
    }

    main .block5 .leftRight .slider .carouselImg .pencil {
        width: 48%;
    }
}

@media screen and (max-width: 1200px){
    main .block2 .textBody {
        max-width: none;
    }
    main .block2 .signup {
        width: 1379px;
    }

    main .block3 {
        padding-top: 60px;
    }

    main .block4 .right figure figcaption {
        margin-top: -119px;
        margin-left: 33%;
        font-size: 18px;
    }

    main .block5 .leftRight .slider .arrowLeft, 
    main .block5 .leftRight .slider .arrowRight {
        margin-bottom: 10%;

    }

    main .block5 .leftRight {
        display: block;
    }

    main .block5 .leftRight .text {
        max-width: none;
    }

    main .block5 .leftRight .slider {
        margin-top: 30px;
        justify-content: center;
    }

    main .block5 .leftRight .slider .carouselImg .pencil {
        width: 50%;
    }

    .block6 {
        display: none;
    }

    .block6_responsive {
        display: block!important;
        background: linear-gradient(180deg, #ffbb37 0%, #fad186 100%)!important;
        .container {
            position: absolute;
            .title {
                margin-left: 5%;
            }
            .text {
                margin-left: 5%;
                max-width: none;
            }
        }
        img {
            width: 100%;
            margin-top: 338px;
        }
    }

    .main .block7 {
        background-size: 88% 80%;
    }

    main .block8 .img {
        width: 487px;
    }

    main .block8 .img .screen {
        width: 448px;
        height: 250px;
        top: 20px;
        left: 20px;
    }

    main .block10 {
        padding: 50px;
        .faqItem {
            padding: 25px 20px;
        }
    }
}

@media screen and (max-width: 1140px){
    main .block4 .right figure figcaption {
        margin-top: -101px;
    }

    main .block9 .containerCarousel {
        padding: 0;
    }

    main .block9 .carouselRow .carouselContent {
        margin-top: 0;
    }

    main .block9 .text {
        max-width: 42%;
        margin-right: 4%;
    }
}

@media screen and (max-width: 1073px){
    main .block3 h2 {
        font-size: 74px;
    }

    main .block4 .right figure figcaption {
        margin-top: -93px;
    }

    main .block5 .leftRight .slider .carouselImg .pencil {
        width: 60%;
    }
    
}

@media screen and (max-width: 991px){

    h2 {
        font-size: calc(120px * var(--font-size-base))!important;
    }
    h3 {
        font-size: calc(80px * var(--font-size-base));
    }

    .block1_parallax {
        display: none!important;
        img {
            position: unset!important;
            width: 100%;
        }
    }

    main .block1_responsive {
        display: block!important;
        background: linear-gradient(119deg, #fadd8d -16%, #f1e7cc 31%, #ececec 54%) !important;
        padding-bottom: 0;
        padding-top: 140px;
        img {
            width: 100%;
            margin-bottom: -4px;
        }
        .subTitle {
            margin-top: 0!important;
        }
        .text {
            max-width: none;
        }
    }

    main .block2 > .container {
        display:block;
    }
    main .block2 {
        padding-top: 31px;
        background: linear-gradient(180deg, #6df2ad 0%, #55bc87 100%);
        .certified-badge {
            right: 10px;
            margin-top: -182px;
        }
        .signup {
            width: auto!important;
            background: none!important;
            padding-left: 0!important;
            text-align: center;
            a {
                border: 10px solid #6ff6b0;
                border-radius: 50px;
            }
            p {
                padding-left: 0!important;
            }
        }
    }

    main .block3 {
        padding: 109px 0 0;
    }

    main .block3 .container {
        display: block;
    }

    main .block3 .slider-wrpr {
        margin-top: 25px;
        margin-left: 12%;
    }

    main .block3 .slider-wrpr .slider {
        left: 53px;
    }

    main .block3 .subTitle {
        color: #fff;
    }

    main .block4 .left {
        max-width: 40%;
        padding-right: 5px;
        border: none;
        min-height: auto;
        max-width: none;
        width: 100%!important;
    }

    main .block4 .left .text {
        width: 100%!important;
    }

    main .block4 .right figure  {
        display: none!important;
    }

    main .block4 .right .desc {
        display: none!important;
    }

    main .block4 .right figure figcaption {
        margin-top: -70px;
        font-size: 13px;
    }

    main .block4 .journeys {
        margin: 35px 0;
    }

    main .block4 .journeys .journey {
        width: 100%;
        height: 169px;
    }

    main .block4 .journeys .journey5 {
        height: 175px!important;
        width: 109%!important;
    }

    main .block4 .descResp {
        display: block;
        h4 {
            margin-bottom: 10px;
        }
    }

    main .block5 .leftRight .slider .carouselImg .pencil {
        width: 564px;
        margin-left: 68px;
    }

    .block6_responsive {
        .title {
           margin-top: 20px!important;
           margin-left: 0!important;
        }
        .text {
            margin-left: 0!important;
            max-width: none;
        }
        img {
            margin-top: 128px;
        }
    }

    main .block7 {
        padding: 25px 0px;
    }

    main .block7 .list .cell .marker {
        margin-right: 5px;
    }

    main .block8 {
        padding-top: 45px;
    }

    main .block8 .text {
        float: none;
        margin-right: 0;
        width: auto;
    }

    main .block8 .img {
        margin-top: 60px;
        margin-left: 50%;
        margin-left: 17%;
    }

    main .block9 {
        padding: 60px 0;
    }

    main .block9 .carouselRow .carouselContent {
        width: 768px;
        height: 330px;
    }

    main .block9 .title {
        max-width: 100%;
    }

    main .block9 .text {
        font-size: 21px;
        margin-right: 8%;
        margin-bottom: 63px;
    }
    main .block9 .carouselRow .carouselContent .carouselAuthor p {
        margin-top: 222px !important;
        margin-left: 60px;
    }

    main .block10 > .container{
        display: block!important;
        .title {
            text-align: left;
            margin-right: 0;
            margin-bottom: 25px;
        }
        .faqList {
            width: 100%;
        }
    }
}

@media screen and (max-width: 820px){
    main .block1_responsive {
        padding-top: 120px;
    }
}

@media screen and (max-width: 768px){

    h2 + p {
        margin-top: 0;
    }

    main .block1 .text {
        margin-top: 0;
        max-width: 100%;
    }

    main .block1 {
        padding-top: 50px;
    }
    main .block1 .subTitle {
        margin-top: 80px!important;
    }

    main .block2 .signup {
        padding-right: 0!important;
        margin-top: -15px;
        
    }

    main .block2 .signup a {
        font-size: 17px;
    }

    main .block2 .signup .sText {
        font-size: 13px;
        left: 0;
    }

    main .block3 {
        padding-top: 40px;
    }

    main .block3 .container > div {
        margin-left: 0;
        float: none;
    }

    main .block3 img {
        width: 296px;
    }

    main .block3 .slider-wrpr .mask {
        margin-left: 142px;
    }

    main .block3 .slider-wrpr .slider {
        left: 168px;
        width: 198px;
        height: 373px;
        top: 3%;
        border-radius: 0;
        .card {
            margin-left: 6px;
            margin-top: 2px;
            border-radius: 25px;
            img {
                border-radius: 16px;
            }
        }
    }


    main .block3 .text {
        max-width: 100%;
    }

    main .block4 > .container {
        padding: 0!important;
    }

    main .block4 .subTitle, main .block4 .title,
    main .block4 .leftRight, main .block4 .descResp {
        padding: var(--container-padding);
    }

    main .block4 .journeysResp::-webkit-scrollbar {
        width: 0;
    }

    main .block4 .journeys {
        padding: 0;
        display: none;
        margin: 0;
    }

    main .block4 .journeysResp {
        display: flex;
        
    }

    main .block4 .journeysResp .journeys {
        display: flex;
    }

    main .block4 .journeys .journey {
        width: 100%;
        height: 209px;
    }

    main .block4 .journeys .journey5 {
        height: 150px;
        width: 100%;
    }

    main .block4 .journeys .journey5 {
        height: 216px !important;
        width: 217px !important;
    }

    main .block4 .journeys .journey5:hover {
        height: 150px;
        width: 100%;
    }

    main .block5 > .container .text {
        margin-top: 13px!important;
    }

    main .block5 .leftRight .slider .arrowLeft,
    main .block5 .leftRight .slider .arrowRight{
        display: none;
    }
    main .block5 .leftRight .slider .carouselImg {
        width: 550px;
        height: 420px;
    }

    main .block5 .leftRight .responsiveArrows {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        z-index: 10;
    }

    main .block5 .leftRight .responsiveArrows .arrowLeft,
    main .block5 .leftRight .responsiveArrows .arrowRight{
        margin: 0 45px;
        z-index: 10;
    }

    main .block5 .leftRight .responsiveArrows .arrowLeft img,
    main .block5 .leftRight .responsiveArrows .arrowRight img{
        width: 30px;
    }

    main .block5 .leftRight .slider .carouselImg .pencil {
        width: 457px;
        margin-left: 26px;
    }

    .block6_responsive .container .title, .block6_responsive .container .text {
        margin-left: 0!important;
        
    }


    main .block7 {
        background-size: 100% 23%;
        background-position: 100% 100%;
    }

    main .block7 .text {
        margin-top: 13px!important;
    }

    main .block7 .list .row {
        display: block;
    }

    main .block7 .list .cell {
        width:auto;
    }

    main .block7 .list .cell .marker {
        height: 16px;
    }

    main .block8 .img {
        margin-left: 0;
    }

    main .block9 .carouselRow .carouselContent {
        width: 576px;
        background-size: 100%;
        height: 236px;
    }

    main .block9 .text {
        font-size: 16px;
        margin-bottom: 30px;
    }

    main .block9 .carouselRow .carouselContent .carouselAuthor p {
        font-size: 16px !important;
        margin-top: 169px !important;
    }
}

@media screen and (max-width: 576px){

    main .block1 .subTitle {
        margin-top: 10px!important;
    }

    main .block2 .certified-badge {
        width: 105px;
        margin-top: -107px;
    }

    main .block3 {
        padding-left: 0;
        padding-top: 43px;
    }

    main .block3 .slider-wrpr {
        margin-left: 10%!important;
    }

    main .block3 .slider-wrpr .mask {
        margin-left: 0px;
    }

    main .block3 .slider-wrpr .slider {
        top: 7px;
        left: 31px;
        height: 375px;
    }

    main .block3 .slider-wrpr .slider .card {
        margin-left: 0px;
        margin-top: 10px;
        border-radius: 28px;
    }

    main .block4 .journeys .journey {
        width: 100%;
    }

    main .block5 {
        padding-top: 40px;
        padding-bottom: 60px;
    }

    main .block5 > .container {
        padding: 0;
        
        .text {
            margin-top: 15px;
        }
    }

    main .block5 .leftRight .slider .carouselImg {
        width: 400px;
        height: 318px;
        img {
            width: 100%;
            &.bubbles {
                width: 104%;
            }
        }
    }

    main .block5 .leftRight .responsiveArrows {
        margin-top: 20px;
    }

    main .block5 .leftRight .responsiveArrows .arrowLeft img,
     main .block5 .leftRight .responsiveArrows .arrowRight img {
        width: 25px;
    }

    main .block5 .leftRight .slider .carouselImg .pencil {
        width: 412px;
        margin-left: 0;
        margin-top: 0;
    }

    .main .block7 {
        background-size: 100% 30%;
        padding-bottom: 140px;
    }

    main .block8 .img {
        width: 370px;
    }

    main .block8 .img .screen {
        width: 340px;
        height: 206px;
        top: 15px;
        left: 15px;
    }

    main .block9 {
        padding: 12px 0;
        padding-bottom: 30px;
    }

    main .block9 .carouselRow .carouselContent {
        background-size: 100% 100%;
    }

    main .block9 .text {
        font-size: var(--text-font-size)!important;
    }

    main .block9 p.author {
        font-size: var(--text-font-size)!important;
    }

    main .block10 {
        padding: 25px 0;
        .faqTitle h4 {
            font-size: 17px!important;
        }
    }
}

@media screen and (max-width: 480px){
    
    main .block5 .leftRight .slider .carouselImg .pencil {
        width: 390px;
    }

    main .block9 .containerCarousel {
        padding-left: 0!important;
    }

    main .block9 .carouselRow .carouselContent {
        width: 100%;
        height: 260px;
    }

    main .block9 .btn {
        height: 14px;
        width: 14px;
    }
}

@media screen and (max-width: 430px){


    main .block5 .leftRight .slider .carouselImg .mainIMG2 {
        top: -95%;
    }
}

@media screen and (max-width: 414px){


    main .block2 .signup a {
        font-size: 15px;
    }

    main .block3 img {
        width: 256px;
    }

    main .block3 .slider-wrpr .slider {
        left: 26px;
        width: 168px;
        height: 324px;
    }

    main .block3 .slider-wrpr .slider .card {
        border-radius: 18px;
    }

    main .block4 .journeys .journey {
        width: 100%;
    }

    main .block5 .leftRight .text {
        margin-top: 20px;
    }

    main .block5 .leftRight .slider .carouselImg {
        height: 252px;
        width: 315px;
    }

    

    main .block5 .leftRight .slider .carouselImg .mainIMG2 {
        top: -100%;
    }

    main .block5 .leftRight .slider .carouselImg .pencil {
        width: 297px;
        margin-top: 25px;
        left: 53px;
    }

    main .block7 .text {
        max-width: none;
    }

    main .block7 .list .cell {
        width: 295px;
        margin-top: 15px;
    }

    main .block8 .img .screen {
        width: 247px;
        height: 141px;
        top: 11px;
        left: 11px;
    }

    main .block8 .img {
        width: 270px;
    }

    main .block9 .text {
        font-size: 15px;
    }

    main .block10> .container .title {
        text-align: left;
    }

    

    main .block10 .faqItem {
        padding: 20px 15px;
        .faqTitle {
            font-size: 15px!important;
        }
        .img {
            margin-left: 15px;
        }
    }
}

@media screen and (max-width: 375px){
    @keyframes photography-animation {
        0% {top: -77%}
        50% {top: -82%}
        100% {top: -77%}
    }
    main .block5 .leftRight .slider .carouselImg .mainIMG2 {
        animation: photography-animation 6s infinite linear;
        top: -77%;
        width: 40%;
        left: 31%;
    }
    main .block5 .leftRight .slider .carouselImg .pencil {
        width: 80%;
        margin-top: 25px;
        left: 35px;
    }
}

@media screen and (max-width: 345px){
    @keyframes photography-animation {
        0% {top: -73%}
        50% {top: -77%}
        100% {top: -73%}
    }
    main .block5 .leftRight .slider .carouselImg .mainIMG2 {
        animation: photography-animation 6s infinite linear;
        top: -73%;
        width: 40%;
        left: 31%;
    }

}