*{
    box-sizing: border-box;
}

:root{
    --font-size-base:1;
    --main-title-font-size:160px;
    --main-sub-title-font-size:68px;
    --text-font-size:28px;
    
    --white-color:#fff;
    --black-color:#000;
    --green-color:#189c72;

    @media screen and (max-width: 1200px) {
        --font-size-base:0.85;
    }
    @media screen and (max-width: 1025px) {
        --font-size-base:0.64;
    }
    @media screen and (max-width: 769px) {
        --font-size-base:0.4;
        --main-title-font-size: 238px;
        --main-sub-title-font-size: 98px;
    }
    @media screen and (max-width: 576px){
        --text-font-size:15px;
    }
    @media screen and (max-width: 480px) {
        --font-size-base:0.26;
      }
}

body {
    margin: 0;
    margin: 0;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}

body::-webkit-scrollbar {
    width: 1em;
  }
   
  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  p, h1, h2, h3, h4 {
    margin: 0;
    
}

  h2{
    font-size: calc(110px * var(--font-size-base));
    font-weight: 700;

    + p{
        margin-top: -4px;
    }
}

h3{
    font-size: calc(80px * var(--font-size-base));
    font-weight: 700;
    + p{
        margin-top:13px;
    }
}
h4{
    font-weight: bold;
    font-size: 28px;
}

a {
    text-decoration: none;
}

main {
    justify-content: flex-start!important;
    align-items: start!important;
}

.container-fluid {
    width: 100%;
}

.container {
    max-width: 1750px;
    margin: 0 auto;
    padding: 0 20px;
}

.d-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fade-in {
    transition: opacity 2s ease, margin-top 2s ease, position 2s ease;
    position: unset;
}

.fade-out {
    opacity: 0;
    transition: opacity 2s ease, margin-top 2s ease, position 2s ease;
    position: relative;
}

p.text{
 font-size:var(--text-font-size);
}

header .aboutUsModal p,
main .block1 .text,
main .block2 .textBody,
main .block3 .text,
main .block4 .left .text, main .block4 .right .desc p, main .block4 .descResp,
main .block5 .leftRight .text,
main .block6 .text, main .block6_responsive .container .text,
main .block8 .text .desc,
main .block10 .faqList .faqText p,
footer .container article p:first-of-type,
footer .footModal p
{
    white-space: pre-wrap;
}


main{
    
    .block1_parallax {
        background-size: cover!important;
        background-position: 100% 100%!important;
        img {
            max-height: 697px !important;
            left: 48% !important;
            width: 100% !important;
            top: 6%;
            z-index: 99;
        }
    }
    .react-parallax-content{
        margin: 0 auto;
    }
    .block1 {
        padding-top: 149px;
        padding-bottom: 60px;
        background: linear-gradient(115deg, #fdd978 17%, #eae4e4 48%) !important;
        z-index:99;
        
        .subTitle {
            color: var(--black-color);
            font-size: calc(var(--main-sub-title-font-size) * var(--font-size-base)); 
            position: relative;
            z-index:999;
        }
        .fade-in {
            margin-top: 0px;
            opacity: 1;
        }
        .fade-out {
            margin-top: 200px;
            opacity: 0;
        }
        .title {
            font-weight: bold;
            color: #5816bc;
            font-size: calc(var(--main-title-font-size) * var(--font-size-base));
            position: relative;
            z-index:999;
        }
        .text {
            margin-top: 8px;
            max-width: 50%;
            position: relative;
            z-index:999;
        }
    }
    .block1_responsive {
        display: none;
    }
    .block2 {
        background-color: #6ff6b0;
        .certified-badge {
            position: absolute;
            right: 58px;
            z-index: 100;
            width: 170px;
            margin-top: -313px;
        }
        .textBody {
            font-size: var(--text-font-size);
            max-width: 80%;
        }
        .signup{
            
            border-radius: 40px;
            margin: 0!important;
            padding: 36px 50px 37px 187px;
            width: 747px;
            background: url('../../public/images/main/signup_bg.png') no-repeat;
            background-size: cover;
            a {
                display: inline-block;
                background-color: #000;
                text-align: center;
                color: #6ff6b0;
                font-weight: bold;
                border-radius: 42px;
                font-size: 27px;
                padding: 20px 33px;
                transition: 0.3s all;
            }
            a:hover {
                transition: 0.3s all;
                transform: scale(1.1, 1.1);
            }
            .sText {
                margin-top: 10px;
                padding-left: 68px;
                font-weight: bold;
                position: relative;
                left: -6px;
            }
        }
    }
    
    .block3 {
        padding: 109px 30px 0;
        background: radial-gradient(at 64% 58%, #808080, #4c4c4c);
        background-repeat: no-repeat;
        background-size: 100% 90%;
        .container {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
        .container > div:not(.slider-wrpr) {
            margin-left: 40%;
            margin-left: 5%;
        }
        .slider-wrpr {
            position: relative;
            
            .mask {
                position: relative;
                z-index:5;
            }
            .slider {
                position: absolute;
                top: 27px;
                transform: rotate(-10deg);
                z-index: 4;
                width: 343px;
                height: 657px;
                left: 10%;
                overflow: hidden;
                border-radius: 40px;
                .card {
                    overflow: hidden;
                    width: 100%;
                    height: 100%;
                    border-radius: 40px;
                    margin-bottom: -1px;
                    position:absolute;
                    transition: opacity 0.5s ease;
                    opacity: 1;
                    
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 39px;
                    }
                }
                
                @keyframes block3 {
                    0% {opacity: 1; margin-top: -16px;}
                    100% {opacity: 0; margin-top: -40%;}
                }
                .fade-outIMG {
                    transition: opacity 0.5s ease, margin-top 0.5s ease;
                    opacity: 0;
                    margin-top: -40%;
                }
                .active {
                    z-index:1;
                }
                .fade-inIMG {
                    transition: opacity 0.5s ease;
                    opacity: 1;
                }
            }
        }
        .subTitle {
            color: var(--white-color);
        }
        .title {
            color: #ffd86f;
        }
        .text {
            color: var(--white-color);
            max-width: 76%;
        }
        .phone {
            
            img {
                position:absolute;
            }
            .phoneIMG {
                position: absolute;
            }
        }
    }

    .block4 {
        padding: 50px 0;
        .title {
            color: #979797;

        }
        .left {
            padding-right: 85px;
            border-right: 7px solid #000;
            min-height: 200px;
            .text {
                width: 400px;
            }
        }
        .right {
            h4{
                margin-bottom: 17px;
            }
            figure {
                img {
                    width: 87%;
                }
                figcaption {
                    font-weight: bold;
                    color: #838383;
                    margin-top: -178px;
                    font-size: 25px;
                    margin-left:40%;
                    display:block;
                }
            }
            .desc {
                padding-left: 60px;
                max-width: 90%;
                display: none;
                
                p {
                    font-size: 17px;
                }
            }
            
        }
        .journeys-img {
            background: url('../../public/images/homepage/journey1active.png'),
                        url('../../public/images/homepage/journey2active.png'),
                        url('../../public/images/homepage/journey3active.png'),
                        url('../../public/images/homepage/journey4active.png'),
                        url('../../public/images/homepage/journey5active.png');
            height: 0;
            width: 0;
        }
        .journeysResp {
            display: none;
            height: 320px;
            margin-bottom: -30px;
            margin-left: auto;
            margin-right: auto;
            overflow: scroll;
            position: relative;
            width: 100%;
            .journey {
                height: 300px;
                scroll-margin: 10px;
                scroll-snap-align: start;
                scroll-snap-stop: normal;
                color: white;
                width: 200px!important;
                margin: 0 10px;
            }
            body main .block4 .journeys .journey5 {
                width: 300px!important;
            }
        }
        .journeysResp::-webkit-scrollbar {
            height: 0;
        }
        
        .x-mandatory {
            scroll-snap-type: x mandatory;
          }
        
        .journeys {
            margin: 80px 0;
            padding: 0 35px;
            
            .journey {
                width: 16%;
                height: 265px;
            }
            .journey:hover, .journey.active{
                cursor: pointer;
            }
            .journey1 {
                background: url('../../public/images/homepage/journey1.png') no-repeat;
                background-size: 100%;
            }
            
             .journey1.active {
                background: url('../../public/images/homepage/journey1active.png') no-repeat;
                background-size: 100%;
            }
            .journey2 {
                background: url('../../public/images/homepage/journey2.png') no-repeat;
                background-size: 100%;
            }
            
             .journey2.active {
                background: url('../../public/images/homepage/journey2active.png') no-repeat;
                background-size: 100%;
            }
            .journey3 {
                background: url('../../public/images/homepage/journey3.png') no-repeat;
                background-size: 100%;
                width: 16%;
            }
            
             .journey3.active {
                background: url('../../public/images/homepage/journey3active.png') no-repeat;
                background-size: 100%;
            }
            .journey4 {
                background: url('../../public/images/homepage/journey4.png') no-repeat;
                background-size: 100%;
                
            }
            
             .journey4.active {
                background: url('../../public/images/homepage/journey4active.png') no-repeat;
                background-size: 100%;
            }
            .journey5 {
                background: url('../../public/images/homepage/journey5.png') no-repeat;
                background-size: 100%;
                height: 280px;
                width: 18%;
            }
            
             .journey5.active {
                background: url('../../public/images/homepage/journey5active.png') no-repeat;
                background-size: 100%;
                height: 280px;
                width: 18%;
            }
        }
        .descResp {
            display: none;
        }
    }

    
    @keyframes block5 {
        0% {margin-top: 14%}
        50% {margin-top:11%}
        100% {margin-top: 14%}
    }

    .block5 {
        background: linear-gradient(180deg, #e4e4e4 0%, #dddddd 50%, #b5b5b5 100%);
        padding: 70px 0;
        .title {
            color: #319ec8;
        }
        
        .leftRight {
            align-items: flex-start;
            .text {
                max-width: 40%;
            }
            .slider {
                justify-content: flex-end;
                align-items: flex-end;
                
                .arrowLeft, .arrowRight{
                    margin-bottom: 14%;
                    width: 53px;
                    height:87px;
                    z-index: 10;
                }
                .arrowLeft:hover, .arrowRight:hover{
                    cursor: pointer;
                }
                .arrowLeft img, .arrowRight img{
                    transition: 0.5s all ease;
                }
                .arrowLeft:hover img {
                    margin-left: -20px;
                    transition: 0.5s all ease;
                }
                .arrowRight:hover img {
                    margin-left: 20px;
                    transition: 0.5s all ease;
                }
                .carouselImg {
                    width: 820px;
                    height: 623px;
                    .pencil {
                        z-index: 2;
                        position: absolute;
                        width: 768px;
                        margin-top: 80px;
                        margin-left: 33px;
                    }
                    img {
                        width: 100%;
                        position: relative;
                    }
                    .bubbles{
                        z-index: 3;
                    }
                    .mainIMG {
                        animation: block5 6s infinite linear;
                        position: relative;
                        width: 45%;
                        left: 29%;
                        top: -78%;
                        z-index: 4;
                    }
                    .mainIMG2{
                        top: -100%;
                        z-index: 4;
                    }
                    .mainIMG3{
                        top: -92%;
                        z-index: 4;
                    }
                }
            }
            .responsiveArrows {
                display: none;
                z-index: 10;
            }
        }
    }

    

    .block6 {
        background: linear-gradient(119deg, #d7d2d6 0%, #f8d8a0 -18%, #ffbc38 100%);
        
        .block6_parallax {
            display: block!important;
            height: 576px;
            img {
                left: 21% !important;
                width: 46% !important;
                top: 1% !important;
                z-index: 999;
            }
            .react-parallax-content {
                margin-left: 50px;
            }
        }
        .container {
            justify-content: flex-end;
        }
        .title {
            color: var(--white-color);
            z-index: 999;
            position: relative;
            margin-left: 41%;
            margin-top: 111px;
        }
        .text {
            position: relative;
            z-index: 999;
            margin-left: 41.5%;
            max-width: 65%;
        }
    }
    .block6_responsive {
        display: none;
    }

    .block7 {
        background: url('../../public/images/homepage/block7_bg.png') no-repeat;
        background-size: 77% 80%;
        background-position: 100% 7%;
        
        padding: 78px 0;
        .title {
            color:var(--green-color);

        }

        .list {
            margin-top: 5px;
            max-width: 84%;
            .row {
                opacity: 0;
            }
            .cell {
                justify-content: flex-start;
                width: 330px;
                margin-top: 25px;

                .marker {
                    width: 10px;
                    height: 18px;
                    background-color:var(--green-color);
                    margin-right: 15px;
                }
                p {
                    font-size: var(--text-font-size);
                    
                }
            }
        }
    }

    .block7visible {
        .row:nth-of-type(1){
            transition: opacity 2s ease, position 2s ease;
            opacity: 1;
        }
        .row:nth-of-type(2){
            transition: opacity 4s ease, position 2s ease;
            opacity: 1;
        }
        .row:nth-of-type(3){
            transition: opacity 6s ease, position 2s ease;
            opacity: 1;
        }
        .row:nth-of-type(4){
            transition: opacity 8s ease, position 2s ease;
            opacity: 1;
        }
    }

    .block8 {
        background: radial-gradient(at 64% 58%, #787878, #555555);
        padding-top: 100px;
        padding-bottom: 60px;
        h3{
            white-space: nowrap;
        }
        .text {
            float: right;
            margin-right: 6%;
            width: 41%;
            .title {
                color: #ffbc38;
            }
            span {
                color: var(--white-color);
            }
            .desc {
                color: var(--white-color);
                font-size: var(--text-font-size);
            }
        }
        .img {
            float: left;
            width: 695px;
            position: relative;
            .comp {
                width: 102%;
                position: relative;
            }
            .screen {
                position: absolute;
                width: 637px;
                height: 356px;
                transition: opacity 0.5s ease;
                opacity: 0;
                top: 29px;
                left: 29px;
            }
            .screen-fade-out{
                transition: opacity 0.5s ease;
                opacity: 1;
            }
        }
        .clear {
            clear: both;
        }
    }

    .block9 {
        padding: 60px 0;
        background-color: #f1f1f1 !important;
        .title {
            color: #7dd0e8;
            max-width: 65%;
            position: relative;
            z-index: 1;
        }
        .containerCarousel {
            padding-right: 0!important;
        }
        .carouselRow {
            justify-content: flex-end;
            
            .carouselContent {
                position: relative;
                background:url("../../public/images/homepage/block9_bg.png") no-repeat;
                background-size: cover;
                width: 1073px;
                margin-top: -139px;
                z-index: 0;
                height: 455px;
                justify-content: center;
                align-items: center;
                .carouselAuthor p {
                    margin-top: 348px !important;
                    margin-left: 116px;
                    font-size: 22px;
                    span {
                        font-weight: bold;
                    }
                }
            }
        }
        .text {
            max-width: 42%;
            margin-right: 98px;
            text-align: center;
            position: absolute;
            transition: opacity 0.5s ease;
            height: 242px;
            opacity: 1;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            margin-bottom: 65px;
        }
        .text.fade-out {
            transition: opacity 0.5s ease;
            opacity: 0;
        }
        .btnsRow {
            display: flex;
            justify-content: center;
            z-index: 1;
            position: relative;
            top: -62px;
            left: -144px;
        }
        .btn {
            height: 16px;
            width: 16px;
            margin: 0 5px;
            border-radius: 50px;
            background-color: #000;
        }
        .btn:hover, .btn.active {
            background-color: #7dd0e8;
            cursor: pointer;
        }
    }

    .block10 {
        background-color: #fde38b;
        padding: 80px;
    }

    .block10 > .container {
        justify-content: flex-start;
        align-items: flex-start;
        .title {
            font-weight: bold;
            margin-right: 80px;
        }
        .faqList {
            width: 70%;
            .faqItem {
                border-top: 1px solid #000;
            }
            .faqItem:last-of-type {
                border-bottom: 1px solid #000;
            }
        }
    }
} 