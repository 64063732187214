.faqItem {
    padding: 25px 50px;
    .faqTitle {
        font-weight: bold;
        h4 {
            font-size: 20px!important;
        }
    }
    img {
        margin-left: 45px;
    }
}

.faqItem:hover {
    cursor: pointer;
}



.faqItemOpen {
    background-color: #f1f1f1;
}

.faqItemOpen .faqText{
    margin-top: 4px;
    opacity: 1;
    height: auto;
}

.faqItemClose .faqText{
    opacity: 0;
    overflow: hidden;
    height: 0;
    font-size: 14px;
}