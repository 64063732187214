.verification {
    background-color: #dcdcdc;
    padding: 155px 0;
    p {
        font-size: 24px;
        span {
            font-weight: bold;
        }
    }
    p.mt {
        margin-top: 28px;
        font-size: 18px;
        margin-bottom: 28px;
    }
    p.mess {
        color: red;
        font-size: 20px;
        display: none;
    }
    a {
        color: #0012ff;
        text-decoration: underline;
        
        font-size: 19px;
    }
}