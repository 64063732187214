@media screen and (max-width: 991px){
    .parentZoneMain {
        .headTitle {
            padding-top: 53px;
            padding-bottom: 40px;
            h1 {
                text-align:center;
            }
        }
        .pz {
            padding: 100px 0px!important;
            padding-top: 0!important;
        }
        .parentMenuResponsive {
            display: block!important;
        }
        .parentMenu {
            display: none;
        }
        .centerScreen{
            h2 {
                display: inline-block;
                font-weight: 800;
                font-size: 30px;
                margin-bottom: 25px;
            }
        }
        footer {
            margin-top: 130px;
        }
    }
    
}

@media screen and (max-width: 768px){

    .parentZoneMain {
        .logo img {
            width: 95px;
            top: 49px;
        }
        .headTitle {
            padding-top: 42px;
            padding-bottom: 25px;
            h1 {
                font-size: 50px;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .parentZoneMain {
         .logo img {
            width: 80px;
            top: 36px;
         }
         .headTitle h1 {
            font-size: 30px;
            text-align: left;
            margin-left: 32%;
        }
    } 
}

@media screen and (max-width: 576px){

    .parentZoneMain .centerScreen h2 {
        font-size: 23px;
    }

    body .parentZoneMain .pz {
        padding: 100px 0px !important;
        padding-top: 0 !important;
    }
}