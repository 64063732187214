.parentMenu {
    .parentMenuLink {
        width: 310px;
        padding: 3px 0 3px 3px;
        background-color: #75c5db;
        .whiteBG {
            padding: 3px 0 3px 3px;
            background-color: #fff;
            background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 45%, rgba(255, 255, 255, 0) 70%, rgba(0, 0, 0, 0) 100%);
        }
        a:not(.whiteBG){
            background: linear-gradient(90deg,  rgba(255, 255, 255, 0) 70%, rgba(0, 0, 0, 0) 80%,  rgba(0, 0, 0, 0.1) 100%);
        }
    }
    .parentMenuLink:first-of-type {
        border-radius: 15px 0 0 0;
        a {
            border-radius: 15px 0 0 0;
        
            p {
                border-radius: 15px 0 0 0; 
            }
        }
        .whiteBG {
            border-radius: 15px 0 0 0;
            
        }
    }
    .parentMenuLink:last-of-type {
        border-radius: 0 0 0 15px;
        a {
            border-radius: 0 0 0 15px;
            p {
                border-radius: 0 0 0 15px;
            }
        } 
        .whiteBG {
            border-radius: 0 0 0 15px;
            
        }
    }
    a {
        padding: 3px;
        color: #000;
        font-size: 23px;
        font-weight: 600;
        width: 100%;
        display: block;

        p {
            padding: 10px;
            width: 77%;
            margin-bottom: unset;
        }
    }
}