

.centerScreen {
    padding: 10px 30px;
    background-color: #72c4db;
    width: 100%;
    h2 {
        display: none;
    }
    .info {
        background-color: #fff;
        padding: 40px;
        .infoRow {
            justify-content: flex-start;
            margin-bottom: 34px;
            font-size: 18px;
            .infoTitle {
                width: 225px;
            }
            .infoText {
                font-weight: 600;
            }
        }

        .infoRow:nth-of-type(5){
            margin-top: 75px;
        }
    }
}

.pz .centerScreen{
    padding: 10px;
}