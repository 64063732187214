@media screen and (max-width: 991px){
    .uploads {
        .screen {
            background-color: #6ff6b0!important;
        }
    }
}

@media screen and (max-width: 768px){
    .uploads {
        .tableDesktop{
            display: none;
        }
        .tableMobile {
            display: block!important;
            tr{
                td:first-child {
                    background-color: #6ff6b0;
                    font-weight: bold;
                }
                td {
                    word-wrap: break-word;
                    font-size: 18px;
                }
            }
            tr:nth-of-type(odd){
                td:last-of-type{
                    background-color: #dcdcdc;
                }
            }
            tr:nth-of-type(even){
                td:last-of-type{
                    background-color: #f3f3f3;
                }
            }
        }
    }
    
}

@media screen and (max-width: 400px){

    

    .uploads .tableMobile tr td {
        font-size: 13px;
    }
}