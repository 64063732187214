.popup {
    .popupClose {
        margin-top: -182px !important;
        position: absolute !important;
        margin-left: 148px !important;
    }
    .popupClose:hover {
        cursor: pointer;
    }
    .popupBG {
        width: 318px !important;
        position: absolute !important;
        margin-top: -104px !important;
        margin-left: -132px !important;
    }
    .popupBG {
        cursor: default!important;
    }
    .popupText {
        position: absolute;
        max-width: 211px;
        margin-top: -221px;
        margin-left: -76px;
        font-size: 15px;
    }
}

.popup2 {
    position: absolute;
    margin-left: 223px;
    margin-top: -137px;
    .popupClose {
        width: 23px!important;
        margin-top: -258px !important;
    }
    .popupText {
        position: absolute;
        margin-top: -127px;
        margin-left: -63px;
    }
}