@media screen and (max-width: 991px){
    .verification {
        background: none;
    }
    .parentZoneMain main {
        background: none!important;
        background-color: #dcdcdc!important;
    }
    .parentZoneMain main {
        background: linear-gradient(180deg, #fff 0%, #fff 74%, #c3c3c3 100%)!important;
        .screen {
            background: none!important;
            .confirmation {
                background: none!important;
            }
        }
    }
}

@media screen and (max-width: 830px){
    .parentZoneMain main .verification {
        padding: 145px 0 50px;
    }
}



@media screen and (max-width: 768px){
    .parentZoneMain main .verification {
        padding-top: 100px;
        padding-bottom: 252px;
    }
}

@media screen and (max-width: 414px){
    .parentZoneMain main .verification {
        padding: 0px 25px;
    }

    .verification p {
        font-size: 16px;
    }

    .verification p.mt {
        margin-top: 28px;
        font-size: 16px;
        margin-bottom: 28px;
    }

    .verification a {
        color: #0012ff;
        text-decoration: underline;
        font-size: 16px;
    }
}

@supports (-webkit-touch-callout: none) {
    .parentZoneMain main .verification {
        padding-top: 0!important;
    }
}