.pac-container {
    &::after {
        display: none;
    }
}

.sendPrize{
    .headTitle {
        background-color: #838383;
        padding-top: 130px;
        h1 {
            color: #fff;
            font-size: calc(65px * var(--font-size-base));
            font-weight: 700;
        }
    }
    main {
        background-color: #dcdcdc;

        .form {
            padding: 100px 30px;
            
            form {
                background-color: #fff;
                padding: 24px 38px;
                max-width: 900px;
                margin: 0 auto;
                flex-direction: row-reverse;
                h3 {
                    color: rgb(51, 51, 51);
                    font-size: 24px;
                    font-weight: 600;
                    margin-bottom: 12px;
                }
                p{
                    font-size: 12px;
                    color: #f00;
                    opacity: 0;
                }
                p.opacity-0 {
                    opacity: 0;
                    transition: 0.3s all ease;
                }
                p.opacity-1 {
                    opacity: 1;
                    transition: 0.3s all ease;
                }
                .inputs-row {
                    div {
                        width: 49%;
                        input {
                            width: 100%;
                        }
                    }
                }
                input {
                    border-radius: 5px;
                    border: 1px solid rgb(217, 217, 217);
                    height: 44px;
                    padding: 0 12px;
                    margin-top: 8px;
                    font-size: 15px;
                    font-weight: 500;
                    width: 100%;
                }
                input, input::placeholder {
                    color: rgb(115, 115, 155);
                    font-size: 15px;
                    font-weight: 500;
                }
                .select-row {
                    select {
                        width: 30%;
                        border-radius: 5px;
                        border: 1px solid rgb(217, 217, 217);
                        height: 44px;
                        padding: 0 5px;
                        margin-top: 8px;
                        font-size: 15px;
                        font-weight: 500;
                        color: rgb(115, 115, 155);
                        width: 110%;
                    }
                    input {
                        width: 100%;
                    }
                }
                .select-row > div {
                    width: 45%;
                }
                .phone-row{
                    input {
                        margin-right: 16px;
                    }
                    button {
                        height: 28px;
                        width: 28px;
                        margin-top: 12px;
                        border: none;
                        outline: none;
                        background: none;
                        color: #fff;
                        font-size: 18px;
                        text-align:center;
                        justify-content: center;
                        img {
                            height:28px;
                            width: 28px;
                        }
                    }
                    button:hover {
                        cursor: pointer;
                    }
                }
                .inputs-row, .select-row {
                    align-items: flex-start;
                }
                
                .submit-btn {
                    justify-content: center;
                    input{
                        height: 50px;
                        width: 170px;
                        text-align:center;
                    }
                    input.active {
                        background-color: rgb(121, 199, 201);
                        color: #000;
                        transition: 0.5s all ease;
                    }
                    input.active:hover {
                        cursor: pointer;
                        font-size: 18px;
                    }
                }
                figure {
                    width: 49%;
                    text-align:center;
                    font-size: 14px;
                    margin-left: 24px;
                    img {
                        height: 130px;
                    }
                    figcaption{
                         margin-top: 12px;
                    }
                }
            }
        }
        .phone-popup-wrapper {
            position: absolute;
            top: 590px;
            right: 48%;
        }
        .phone-popup {
            background: url('../../public/images/signUp/popupBG.png') no-repeat;
            position: relative;
            background-size: 300px 190px;
            background-position: center;
            width: 300px;
            height: 190px;
            img {
                height: 25px;
                position: relative;
                left: 263px;
                top: 8px;
            }
            img:hover {
                cursor: pointer;
            }
            p {
                color: #000!important;
                margin: 0!important;
                font-size: 15px!important;
                position: relative;
                max-width: 211px;
                left: 49px;
                top: 14px;
            }
        }
    }
    
}