@media screen and (max-width: 991px){
    .settings {
        .screen {
            background-color: #ff8338!important;
        }
    }
}

@media screen and (max-width:419px){
    .settings {
        .screen {
            background-color: #ff8338!important;
            padding-left: 1px!important;
            padding-right: 1px!important;
            .centerScreen {
                h2 {
                    margin-left: 45px;
                }
                .generateCode {
                    padding: 15px 50px;
                }
                .switch {
                    width: 292px;
                }
            }
            .info {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}

@media screen and (max-width:375px){
    .settings .pz .centerScreen {
        padding-left: 15px;
        padding-right: 6px;
        .info {
            padding: 19px 6px;
        }
    }
}