


@media screen and (max-width: 768px){
    .sendPrize {
        .inputs-row, .select-row {
            display: block;
            div, select {
                width: 100%!important;
            }
        }
    }
    .sendPrize main .phone-popup-wrapper {
        top: 645px;
        right: 44%;
    }
}

@media screen and (max-width: 576px){

    .sendPrize .headTitle {
        background-color: #838383;
        padding-top: 55px;
    }

    .sendPrize main .form form {
        padding: 24px 12px;
        display: block;
    }

    .sendPrize main .form form h3 {
        font-size: 18px;
    }

    .sendPrize main .form form figure {
        margin-left: auto;
        margin-right: auto;
    }

    .sendPrize main .phone-popup-wrapper {
        top: 750px;
        right: 48px;
    }
}