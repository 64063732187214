.confirm-popup {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    .inner-popup {
        background-color: #fff;
        width: 300px;
        height: 250px;
        border-radius: 16px;
        padding: 16px;
        .popup-btns {
            margin-top: 38px;
            width: 70%;
            margin-left: auto;
            margin-right: auto;
            button {
                text-align:center;
                color: #000;
                transition: 0.5s all ease;
                border: none;
                border-radius: 12px
            }
            button:hover {
                cursor: pointer;
                font-size: 15px;
            }
            button:first-of-type {
                width: 68px;
                height: 35px;
                background-color: rgb(121, 199, 201);
            }
            button:last-of-type {
                width: 110px;
                height: 35px;
                background-color: rgb(253, 80, 36);
            }
        }
    }
    .inner-popup > button {
        width: 68px;
        height: 35px;
        background-color: rgb(121, 199, 201);
        border: none;
        margin-left: auto;
        margin-left: 39%;
        border-radius: 12px;
        margin-top: 28px;
        transition: 0.5s all ease;
    }
    .inner-popup > button:hover {
        cursor: pointer;
        font-size: 15px;
    }
    .inner-popup > p {
        margin-top: 46px;
        text-align: center;
    }
}