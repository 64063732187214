@media screen and (max-width: 991px) {

    .parentZoneMain main .screen {
        background-color: #72c4db;
        .centerScreen{
            padding-top: 20px;
        }
        
    }
    .pz .centerScreen{
        padding: 10px 30px;
    }
}

@media screen and (max-width: 576px) {

    .centerScreen {
        .info {
            padding: 19px;
            .infoRow {
                display: block;
                font-size: 19px;
                margin-bottom: 27px;
            }
            .centerScreen .info .infoRow:nth-of-type(5){
                margin-top: 58px;
            }
        }
    }
}

