@media screen and (max-width: 991px){
    .parentZoneMain .messPageTitle {
        margin-top: 80px!important;
    }
}

@media screen and (max-width: 768px){
    .parentZoneMain .messPageTitle {
        margin-top: 110px!important;
    }
}