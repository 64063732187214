@media screen and (max-width: 1382px){

    .confirmation {
        width: 940px;
        margin: 0 auto;
        form input[name=code] {
            margin-left: 27%;
            max-width: 43%;
        }

        form .changeBtn {
            margin-left: 42%;
        }
    }

}

@media screen and (max-width: 991px){

    
    .parentZoneMain main {
        background: linear-gradient(180deg, #fff 0%, #fff 74%, #c3c3c3 100%)!important;
        .screen {
            background: none!important;
            .confirmation {
                background: none!important;
            }
        }
    }

    .confirmation {
        width: 728px;
        margin: 0 auto;
        
        p {
            font-size: 19px;
        }
    }

    .confirmation form input[name=code] {
        margin-left: 18%;
        max-width: 55%;
    }

    .confirmation form .changeBtn {
        margin-left: 36%;
    }

}

@media screen and (max-width: 768px){

    .confirmation {
        width: 493px;
        padding: 90px 0;
        padding-top: 35px;
        p {
            font-size: 17px;
        }
    }

    .changeotp {
        padding-top: 108px;
    }

    .confirmation form input[name=code] {
        margin-left: 0%;
        max-width: 80%;
    }

    .confirmation form .changeBtn {
        margin-left: 24%;
    }

    .confirmation form .checkbox .checkboxBtn {
        width: 35px;
    }

    .confirmation form .checkbox .checkboxBtn:not(#noNeeded){
        width: 47px;
    }

    #submitDiv {
        margin-top:55px;
        border-radius: 60px;
        padding: 12px;
        margin-left: auto;
        margin-right: auto;
        input {
            font-size: 20px;
            width: 176px;
        }
    }
}

@media screen and (max-width: 576px){

    .confirmation {
        width: 375px;
        padding-top: 0;
        padding-bottom: 80px;
    }

    .changeotp {
        padding-top: 108px;
    }

    .confirmation form input[name=code] {
        margin-left: 0;
        max-width: 90%;
        padding: 0;
    }

    .confirmation form .changeBtn {
        margin-left: 28%;
    }

    .confirmation form p.mess {
        max-width: none;
    }

    .confirmation form .checkbox .checkboxBtn:not(#noNeeded){
        width: 47px;
    }
}

@media screen and (max-width: 414px){

    .parentZoneMain main .screen {
        padding: 100px 10px!important;
    }

    .confirmation {
        width: 100%;
    }

    .changeotp {
        padding-top: 30px;
    }

    .confirmation form input[name=code] {
        font-size: 69px;
    }

    .confirmation form .changeBtn {
        margin-left: 11%;
    }

    .confirmation form .checkbox input[type=text] {
        width: 90%;
    }

    .confirmation form .checkbox .checkboxBtn:not(#noNeeded){
        width: 60px;
    }
}

@supports (-webkit-touch-callout: none) {
    .confirmation form input[name=code] {
        font-size: 80px;
    }
  }