.signin {
    background-color: #dcdcdc;
    padding: 43px 20px;
    .title {
        font-size: 30px;
        padding-top: 30px;
    }
    form {
        margin-top: 35px;
        text-align: center;
        .error {
            font-size: 18px;
            color: red;
            display: none;
        }
        input {
            margin-top: 30px;
            width: 345px;
            padding: 11px 24px;
            border-radius: 30px;
            font-size: 18px;
            border: 1px solid #8f8f8f;
        }
        a {
            margin-right: 12%;
            display: inline-block;
            margin-top: 15px;
            text-decoration: underline;
        }
        a:hover {
            text-decoration: none;
        }
        .inactiveSubmit {
            background-color: #9d9c9c;
            padding: 30px;
            border-radius: 60px;
            box-shadow: 0px 0px 15px 4px #bababa;
            width: 280px;
            margin: 0 auto;
            margin-top: 50px;
            input {
                color: #9d9c9c;
            }
        }
        .inactiveSubmit:hover {
            cursor: not-allowed
        }
        .inactiveSubmit input:hover {
            cursor: not-allowed!important
        }
        input[type="submit"]{
            border: none;
            background-color: #000;
            padding: 10px 0;
            font-weight: 700;
            font-size: 25px;
            border-radius: 50px;
            width: 223px;
            margin-top: 0;
        }
        .activeSubmit {
            width: 280px;
            margin: 0 auto;
            background-color: #6ff6b0;
            padding: 30px;
            border-radius: 60px;
            box-shadow: 0px 0px 29px 10px #a1a1a1;
            margin-top: 50px;
            input {
                color: #6ff6b0;
            }
        }
        .activeSubmit:hover, input[type="submit"]:hover {
            cursor: pointer;
        }
    }
}