.messages {
    
    .centerScreen {
        background-color: #fde38b;
        .info {
            table {
                th {
                    background-color: #fde38b;
                }
                td {
                    word-break: break-all;
                }
            }
        }
    }
}