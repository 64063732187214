.forgot {
    min-height: 60vh;
    .title {
        font-size: 18px;
        text-align: center;
        margin-top: 80px;
    }
    .success {
        display: none;
    }
    form {
        margin-top: 5px;
        a {
            margin-right: 0;
        }
    }
}