.confirmation {
    background-color: #dcdcdc;
    padding: 148px 0;
    .otp-text {
        margin-top: 16px;
    }
    p {
        font-size: 24px;
    }
    p.error {
        font-size: 18px;
        margin-top: 10px;
        display: none;
        color: red;
    }
    p.code span{
        background-color: yellow;
    }
    form {
        input[name='code']{
            margin-left: 40%;
            font-size: 100px;
            margin-top: 25px;
            background: none;
            border: none;
            max-width: 37%;
            padding-left: 35px;
            color: #000;
            font-family: 'Atkinson Hyperlegible';
        }
        input[name='code']:active {
            border: none;
        }
        .changeBtn {
            margin-left: 51%;
            color: #fff;
            font-weight: 500;
            padding: 8px 20px;
            background-color: #000000;
            border-radius: 35px;
            border: none;
            margin-top:25px;
        }
        .changeBtn:hover {
            cursor: pointer;
        }
        p.mess {
            margin-top: 35px;
            max-width: 100%;
        }
        p.title {
            font-weight: bold;
            margin-top: 45px;
        }
        .checkbox{
            .checkboxBtn {
                padding: 3px;
                width: 28px;
                height: 28px;
                background-color: #fff;
                border: 1px solid #8f8f8f;
                margin-right: 15px;
                .checkboxBtnActive {
                    background-color: #000;
                    width: 100%;
                    height: 100%;
                }
            }
            .checkboxBtn:hover {
                cursor: pointer;
            }
            p.error {
                font-size: 18px;
                display: none;
            }
            p{
                span {
                    font-weight: bold;
                }
            }
            input:disabled{
                background-color:#8f8f8f;
            }
            .disabled {
                background-color:#8f8f8f;
            }
            input[type="text"], .react-tel-input .form-control {
                border: 1px solid #8f8f8f;
                border-radius: 35px!important;
                padding: 15px 15px;
                max-width: 530px;
                width: 310px;
                margin-top: 20px;
                padding: 15px 15px;
            }
            .react-tel-input {
                margin-top: 20px;
                border-radius: 35px 0 0 35px!important;
            }
            .react-tel-input .form-control {
                padding: 20px 45px;
            }
            .react-tel-input .selected-flag {
                border-radius: 35px 0 0 35px!important;
            }
            .react-tel-input .flag-dropdown.open .selected-flag {
                border-radius: 35px 0 0 35px!important;
            }
            .selected-flag:hover {
                border-radius: 35px 0 0 35px!important;
            }
            
            .react-tel-input .flag-dropdown {
                border-left: 1px solid #8f8f8f;
                border-radius: 35px 0 0 35px!important;
            }
        }
        input[type="submit"]{
            border: none;
            background-color: #000;
            padding: 10px 70px;
            font-weight: 700;
            font-size: 30px;
            border-radius: 50px;
        }
        .inactiveSubmit {
            background-color: #9d9c9c;
            padding: 30px;
            border-radius: 60px;
            box-shadow: 0px 0px 15px 4px #bababa;
            input {
                color: #9d9c9c;
            }
        }
        .inactiveSubmit:hover {
            cursor: not-allowed
        }
        .inactiveSubmit input:hover {
            cursor: not-allowed
        }
        .activeSubmit {
            background-color: #6ff6b0;
            padding: 30px;
            border-radius: 60px;
            box-shadow: 0px 0px 15px 4px #bababa;
            input {
                color: #6ff6b0;
            }
        }
        .activeSubmit:hover {
            cursor: pointer;
        }
        .activeSubmit input:hover {
            cursor: pointer;
        }
        .activeSubmit:hover {
            cursor: pointer;
            transform: scale(1.1, 1.1);
            transition: 0.3s all;
        }
        .activeSubmit input:hover {
            cursor: pointer;
        }
    }
}