.settings {
    .centerScreen {
        background-color: #ff8338;
        .switch {
            width: 313px;
            margin-top: 30px;
            label {
                padding: 28px 5px 20px;
                div {
                    p {
                        font-size: 16px;
                        
                    }
                }
                p{
                    max-width: 175px;
                    margin-top: -2px;
                }
            }
        }
        .generate-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            max-width: 318px;
        }
        .generateCode {
            border-radius: 25px;
            background-color: #000;
            color: #fff;
            font-weight: bold;
            border: none;
            font-size: 19px;
            padding: 15px 60px;
        }
        .generateCode:hover {
            cursor: pointer;
        }
    }
}