@media screen and(max-width: 768px){
    .successReg {
        max-width: 90%;
    }
    
}

@media screen and(max-width: 414px){
    .parentZoneMain main .sr {
        padding-top: 70px!important;
    }
    .successReg {
        padding-top: 0;
    }
    
}

@supports (-webkit-touch-callout: none) {
    .parentZoneMain main .sr {
        padding-top: 70px!important;
    }
}