
.switch2{
    display: flex;
    width: 60px;
    height: 32px;
    border-radius: 27px;
    background-color: #bdc3c7;
    cursor: pointer;
    transition: all .3s;
    width: 100%;
  }
  .switch2 input{
    display: none;
  }
  .switch2 div:not(.loading){
    position: relative;
    height: 40px;
    border-radius: 25px;
    background-color: #6ff6b0;
    top: -4px;
    left: 0px;
    box-shadow: 0px 0px 0.5px 0.5px rgb(170,170,170);
    transition: all .2s;
    padding: 0 11px;
    text-align: center;
  }

  .switch2 .loading {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: -3px;
  }
  
  .switch2-checked{
    background-color: #b8b8b8;
  }