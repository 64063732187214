@media screen and (max-width: 820px) {

    header {
        height: 75px;
        .menu {
            a{
                span {
                    display: none;
                }
            }
            .signup-btn-wrapper {
                display: none;
            }
        }

        .signUpBtnMobile {
            padding: 6px 0;
            display: block;
            text-align: right;
            height: 70px;
            position: absolute;

            a {
                padding: 9px 16px;
                border-radius: 30px;
                background-color: #000000;
                color: #72c4db;
                display: inline-block;
                font-size: 22px;
                font-weight: 500;
            }
        }
    }
}

@media screen and (max-width: 576px) {

    header {
        padding-top: 10px;

        .logo {
            img {
                width: 66px;
                top: 24px;
            }

            &.active-user-mainpage img {
                top: 41px;
            }
        }

        .menu a, .menu p  {
            font-size: 14px;
        }

        .signUpBtnMobile {
            margin-top: 10px;
            height: 60px;
            .signup-btn-wrapper {
                width: 155px;
                p {
                    font-size: 10px;
                    margin-top: 3px;
                }
            }
            a {
                font-size: 14px;
            }
        }

        .aboutUsModal {
            padding: 16px 28px;
            top: 54px;
            .closeAboutUs button img {
                width: 29px;
            }
            p {
                margin-top: 40px;
                font-size: 15px;
            }
        }
    }
}

@media screen and (max-width: 375px) {

    header {
        padding-top: 10px;

        .logo {
            img {
                width: 66px;
            }
        }

        .menu a {
            font-size: 14px;
            margin: 0 6px;
        }

        .signUpBtnMobile {
            margin-top: 10px;

            a {
                font-size: 14px;
            }
        }

        .signout {
            p, a {
                margin: 0 4px;
            }
            p {
                font-size: 14px;
            }
        }
    }
}